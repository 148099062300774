import { graphql } from 'gatsby'
import React from 'react'
import Image from 'gatsby-plugin-sanity-image'
import BlockContent from '@sanity/block-content-to-react'
import { sanityConfig, serializers } from '../utils/helpers'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Services = ({ data }) => {
  const page = data.sanityBasicPage
  const image = page?.mainImage?.image
  return (
    <Layout>
      <Seo title="Services" />
      {image ? (
        <div className="sidebar-image image">
          <Image {...image} alt={page.mainImage.alt} />
        </div>
      ) : (
        ''
      )}
      <article>
        <h1>Services</h1>
        {page?._rawBody ? (
          <BlockContent
            blocks={page._rawBody}
            serializers={serializers}
            {...sanityConfig}
          />
        ) : (
          ''
        )}
        <ul>
          {data.allSanityServices.edges.map((edge, index) => (
            <li key={index}>
              <div className="h3">{edge.node.title}</div>
              <BlockContent
                blocks={edge.node._rawBody}
                serializers={serializers}
                {...sanityConfig}
              />
            </li>
          ))}
        </ul>
      </article>
    </Layout>
  )
}

export default Services

export const query = graphql`
  query ServicesQuery {
    allSanityServices(sort: { fields: orderRank }) {
      edges {
        node {
          id
          title
          description
          _rawBody
        }
      }
    }
    sanityBasicPage(_id: { eq: "services" }) {
      title
      _rawBody
      mainImage {
        alt
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
